import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

function ServiceSlider(props) {
    const items = Object.values(props); // преобразуем в массив
    const swiperRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(1);

    useEffect(() => {
        if (swiperRef.current) {
            setCurrentSlide(swiperRef.current.swiper.realIndex + 1);
        }
    }, []);

    const handlePrevClick = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const handleNextClick = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const handleSlideChange = (swiper) => {
        setCurrentSlide(swiper.realIndex + 1);
    };

    return (
        <>
            {items.length ? (
                <>
                    <div className="main-service__controls">
                        <div className="main-service__main-title"></div>
                        <div className="main-service__navigation">
                            <div className="main-service__counter">
                                <span className="slide-current">{String(currentSlide).padStart(2, '0')}</span>
                                <span className="slide-all">/ {String(items.length).padStart(2, '0')}</span>
                            </div>
                            <div className="main-service__arrows">
                                <div className="main-service__prev nav-arrow" onClick={handlePrevClick}>
                                    <div className="slider__prev-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             width="21.75" height="40.937"
                                             viewBox="0 0 21.75 40.937">
                                            <path
                                                d="M198.656,500.682l18.569-18.5a1.151,1.151,0,0,0,0-1.639,1.163,1.163,0,0,0-1.646,0l-19.4,19.322a1.152,1.152,0,0,0,0,1.64l19.4,19.314a1.174,1.174,0,0,0,.819.343,1.139,1.139,0,0,0,.818-0.343,1.152,1.152,0,0,0,0-1.64Z"
                                                transform="translate(-195.844 -480.219)"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="main-service__next nav-arrow" onClick={handleNextClick}>
                                    <div className="slider__next-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             width="21.75" height="40.937"
                                             viewBox="0 0 21.75 40.937">
                                            <path
                                                d="M1721.34,500.682l-18.57-18.5a1.163,1.163,0,1,1,1.65-1.639l19.4,19.322a1.165,1.165,0,0,1,0,1.64l-19.4,19.314a1.16,1.16,0,1,1-1.64-1.64Z"
                                                transform="translate(-1702.44 -480.219)"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-service__slider-wrap" style={{ backgroundColor: '#f0f3f4' }}>
                        <div className="main-service__decore-subtitle"></div>
                        <div className="main-service__slider">
                            <Swiper
                                ref={swiperRef}
                                slidesPerView={1}
                                loop={true}
                                pagination={false}
                                autoplay={{
                                    delay: 5000,
                                }}
                                onSlideChange={handleSlideChange}
                            >
                                {items.map((item) => (
                                    <SwiperSlide key={`viewed-${item.ID}`}>
                                        <div className="main-service__slide">
                                            <div className="main-service__image">
                                                <img
                                                    src={item.PREVIEW_PICTURE.SRC}
                                                    alt={item.PREVIEW_PICTURE.ALT}
                                                    loading="lazy"
                                                />
                                            </div>
                                            <div className="main-service__content">
                                                <div className="main-service__title mt-[20px]">{item.NAME}</div>
                                                <div
                                                    className="main-service__text text"
                                                    id="slider_text"
                                                    dangerouslySetInnerHTML={{ __html: item.FIELDS.PREVIEW_TEXT }}
                                                ></div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        <div className="main-service__decore-title"></div>
                    </div>
                </>
            ) : null}
        </>
    );
}

export { ServiceSlider };